<template>
  <div class="buttons are-small">
   <button class="button is-white is-small has-text-weight-semibold has-text-link" @click.stop="viewActionHandle">View</button>
  </div>
</template>

<script>
  import router from "@/router";

  import { defineComponent } from "vue";
  export default defineComponent({
    props: ["rowIndex", "prop", "data"],
    methods: {
      viewActionHandle () {
        router.push({ name: "project-detail", params: { slug: this.data[this.rowIndex].slug,  path: this.data[this.rowIndex].path }})
      }
    },
  })

</script>

<style lang="scss" scoped>
  .buttons {
    padding-top: 7px
  }
</style>
